<template>
  <b-overlay
    :show="loading"
    :variant="overlayVariant"
    :opacity="overlayOpacity"
    rounded="sm"
  >
    <b-row class="match-height">
      <b-col
        cols="12"
      >
        <b-alert
          :show="log_not_found"
          variant="danger"
          class="mb-50"
        >
          <div class="alert-body">
            <feather-icon
              icon="XIcon"
              class="mr-50"
            />
            <span>Log Entry not found. Click <router-link :to="{ name: 'log-entries' }">here</router-link> to view log entries.</span>
          </div>
        </b-alert>
        <b-card v-if="selectedLoggableItemHistory && !log_not_found">
          <b-card-title>{{ selectedLoggableItemHistory.name }}</b-card-title>
          <b-card-sub-title v-if="selectedLoggableItemHistory.created_at">
            <p><strong>Submitted Date:</strong> {{ selectedLoggableItemHistory.created_at | moment('LLL') }}</p>
            <p><strong>Submitted By: </strong> {{ selectedLoggableItemHistory.user.name }} ({{ selectedLoggableItemHistory.user.email }})</p>
            <p><strong>Last Updated:</strong> {{ selectedLoggableItemHistory.updated_at | moment('LLL') }}</p>
          </b-card-sub-title>
          <b-card-body>
            <b-form>
              <b-row>
                <b-col
                  v-for="(item) in selectedLoggableItemHistory.loggable_item.question_orders"
                  :key="item.id"
                  cols="12"
                >
                  <div v-if="item.visible">
                    <b-form-group
                      v-if="item.question.input_type.name !== 'multi-select-modal' && item.question.input_type.name !== 'multi-select-dropdown' && item.question.input_type.name !== 'textarea'"
                      :label="item.question.text"
                      :label-for="`question${item.question_number}`"
                    >
                      <b-form-input
                        :id="`question${item.question_number}`"
                        :value="getResponse(item.question_id, item.question.input_type.name)"
                        :name="`question${item.question_number}`"
                        disabled
                      />
                    </b-form-group>

                    <b-form-group
                      v-else-if="item.question.input_type.name === 'textarea'"
                      :label="item.question.text"
                      :label-for="`question${item.question_number}`"
                    >
                      <b-form-textarea
                        :value="getResponse(item.question_id, item.question.input_type.name)"
                        :name="`question${item.question_number}`"
                        rows="3"
                        max-rows="8"
                        disabled
                      />
                    </b-form-group>

                    <b-form-group
                      v-else-if="item.question.input_type.name === 'multi-select-dropdown'"
                      :label="item.question.text"
                      :label-for="`question${item.question_number}`"
                    >
                      <b-table
                        :fields="fields2"
                        :items="getMultiResponses(item.question_id)"
                        responsive
                        sticky-header
                        striped
                        bordered
                        class="mt-2"
                      >
                        <template #cell(name)="data">
                          {{ data.item.option_choice.label }}
                        </template>

                        <template #head(name)>
                          <span>{{ item.question.option_group.name }}</span>
                        </template>
                      </b-table>
                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="item.question.text"
                      :label-for="`question${item.question_number}`"
                    >
                      <b-table
                        :fields="fields"
                        :items="getMultiResponses(item.question_id)"
                        responsive
                        sticky-header
                        striped
                        bordered
                        class="mt-2"
                      >
                        <template #cell(name)="data">
                          {{ data.item.option_choice.label }}
                        </template>
                        <template #cell(gold_standard_time)="data">
                          {{ data.item.option_choice.gold_standard_time.time }} ({{ unitsOfTimeFormatted(data.item.option_choice.gold_standard_time.time) }})
                        </template>
                        <template #cell(actual_time_spent)="data">
                          {{ data.item.answer_time }}
                        </template>
                        <template #cell(performance_indicator)="data">
                          <b-badge
                            :variant="getPerformanceColour(getPerformancePercentage(data.item.option_choice.gold_standard_time.time,data.item.answer_time))"
                          >
                            {{ getPerformanceTitle(getPerformancePercentage(data.item.option_choice.gold_standard_time.time,data.item.answer_time)) }}
                          </b-badge>
                        </template>
                      </b-table>
                    </b-form-group>
                  </div>
                </b-col>

                <!-- totals -->
                <b-col
                  v-if="activeUser.currentRole.name === 'dental_hygienist'"
                  cols="12"
                >
                  <b-alert
                    :show="true"
                    variant="info"
                    class="mb-50"
                  >
                    <div class="alert-body">
                      <feather-icon
                        icon="InfoIcon"
                        class="mr-50"
                      />
                      <span>Total Gold Standard Time: <strong>{{ getTotalGoldStandardTime() }} ({{ unitsOfTimeFormatted(getTotalGoldStandardTime()) }})</strong></span>
                    </div>
                  </b-alert>
                </b-col>

                <!-- totals -->
                <b-col
                  v-if="activeUser.currentRole.name === 'dental_hygienist'"
                  cols="12"
                >
                  <b-alert
                    :show="true"
                    variant="info"
                    class="mb-50"
                  >
                    <div class="alert-body">
                      <feather-icon
                        icon="InfoIcon"
                        class="mr-50"
                      />
                      <span>Total Actual Time Spent: <strong>{{ getTotalActualTime() }} ({{ unitsOfTimeFormatted(getTotalActualTime()) }})</strong></span>
                    </div>
                  </b-alert>
                </b-col>

                <!-- submit and reset -->
                <b-col
                  cols="12"
                  class="mt-1"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    :disabled="!selectedLoggableItemHistory.can_edit"
                    :to="{ name: 'log-entries-edit', params: { loggableItemHistoryId: selectedLoggableItemHistory.id }}"
                  >
                    {{ $t('buttons.edit') }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    :to="{ name: 'log-entries' }"
                  >
                    {{ $t('buttons.back') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardBody, BCol, BRow, BButton, BLink, BForm, BFormGroup, BFormInput, BFormTextarea, BOverlay, BTable, BBadge, BAlert, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import {
  heightFade,
} from '@core/directives/animations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BCardBody,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BOverlay,
    BTable,
    BBadge,
    BAlert,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BCardTitle,
    BCardSubTitle,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      log_not_found: false,
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'gold_standard_time', label: 'Gold Standard Time' },
        { key: 'actual_time_spent', label: 'Actual Time Spent' },
        { key: 'performance_indicator', label: 'Performance Indicator' },
      ],
      fields2: [
        { key: 'name', label: 'Name' },
      ],
      loggableItemId: null,
      loading: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
      responses: [],
      serviceResponses: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('loggableItems', ['selectedLoggableItemHistory']),
  },
  async created() {
    const { loggableItemHistoryId } = this.$route.params
    // await this.resetUser();
    try {
      await this.getLoggableItemHistory(loggableItemHistoryId)
    } catch (err) {
      if (err && err.response && err.response.status === 404) {
        this.log_not_found = true
      }
    }
  },
  methods: {
    ...mapActions('loggableItems', ['getLoggableItemHistory']),
    getPerformancePercentage(goldStandardTime, actualTime) {
      const t1 = moment.duration(goldStandardTime).asSeconds()
      const t2 = moment.duration(actualTime).asSeconds()
      return (t2 / t1) * 100
    },
    getPerformanceColour(num) {
      if (num <= 100) return 'success'
      if (num > 100 && num <= 140) return 'primary'
      if (num > 140 && num <= 170) return 'warning'
      if (num > 170) return 'danger'
      return 'primary'
    },
    getPerformanceTitle(num) {
      if (num <= 100) return 'Excellent'
      if (num > 100 && num <= 140) return 'Good'
      if (num > 140 && num <= 170) return 'Satisfactory'
      if (num > 170) return 'Need to re-evaluate'
      return 'primary'
    },
    getTotalGoldStandardTime() {
      if (this.selectedLoggableItemHistory.responses.length > 0) {
        const filteredResponses = this.selectedLoggableItemHistory.responses.filter(r => r.option_choice && r.option_choice.gold_standard_time)
        const gst = filteredResponses.map(g => moment.duration(g.option_choice.gold_standard_time.time).asSeconds())
        const sum = gst.reduce((a, b) => a + b, 0)
        return moment().startOf('day').seconds(sum).format('HH:mm:ss')
      }
      return null
    },
    getTotalActualTime() {
      if (this.selectedLoggableItemHistory.responses.length > 0) {
        const ast = this.selectedLoggableItemHistory.responses.map(a => moment.duration(a.answer_time).asSeconds())
        const sum = ast.reduce((a, b) => a + b, 0)
        return moment().startOf('day').seconds(sum).format('HH:mm:ss')
      }
      return null
    },
    unitsOfTime(timestamp) {
      const minutes = moment.duration(timestamp).asMinutes()
      const cursor = Math.floor(minutes / 15)
      let units = 0
      if (cursor === 0) {
        if (minutes <= 7.5) {
          units += 0.5
        } else {
          units += 1
        }
      } else {
        units = cursor
        const diff = minutes - (15 * cursor)
        if (diff <= 7.5) {
          units += 0.5
        } else {
          units += 1
        }
      }
      return units
    },
    unitsOfTimeFormatted(timestamp) {
      const units = this.unitsOfTime(timestamp)
      if (units <= 1) {
        return `${units} unit`
      }

      return `${units} units`
    },
    getResponse(id, type) {
      try {
        let responseText
        let response
        if (this.selectedLoggableItemHistory.responses.length === 0) {
          response = this.selectedLoggableItemHistory.draft.find(r => r.question_id === id)
        } else {
          response = this.selectedLoggableItemHistory.responses.find(r => r.question_id === id)
        }
        if (type !== 'multi-select-dropdown' && type !== 'multi-select-modal' && type !== 'single-select') {
          responseText = response[`answer_${type}`]
          if (type === 'datetime') {
            responseText = moment(responseText).format('LLL')
          }
          if (type === 'textarea') {
            responseText = response.answer_text
          }
          if (type === 'boolean') {
            if (response.answer_yn === true) {
              responseText = 'Yes'
            } else {
              responseText = 'No'
            }
          }
          return responseText
        }

        responseText = response.option_choice.label
        return responseText
      } catch (e) {
        return ''
      }
    },
    getMultiResponses(id) {
      let responses
      if (this.selectedLoggableItemHistory.responses.length === 0) {
        responses = this.selectedLoggableItemHistory.draft.filter(r => r.question_id === id)
      } else {
        responses = this.selectedLoggableItemHistory.responses.filter(r => r.question_id === id)
      }
      return responses
    },
  },
  metaInfo() {
    return { title: this.$t('page_titles.view_log_entry'), titleTemplate: 'DenTyme - %s' }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
